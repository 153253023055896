.header_profile_part {
    display: flex;
    flex-direction: row;
}

.search_submit {
    background: url('Images//search_icon.png') no-repeat center center;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 15px;
}

.showresult {
    position: absolute;
    z-index: 1000;
    display:block !important;
}