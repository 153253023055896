.total-team {
    height: 22px;
    color: #9B9B9B !important;
    font-size: 12px !important;
    letter-spacing: -0.09px;
    line-height: 22px;
}

.club-name-overview {
    font-size: 15px;
    color: #606060 !important;
    font-family: 'Avenir LT Std';
    font-weight: 900;
    letter-spacing: -0.36px;
    line-height: 19px;
    text-align: center;
}

.dropdown-menu.show {
    top: 15px !important;
    border-radius: 5px !important;
}

.fill {
    width: 100%;
}

.player, .team {
    color: #606060;
    font-size: 14px;
}

    .player.score {
        font-size: 25px;
        font-weight: bold;
    }

.player-row {
    align-items: center;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    margin-left: 20px;
    padding: 11px 0;
}

    .player-row:last-child {
        border-bottom: none;
    }

.team-row {
    align-items: center;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    /*justify-content: space-between;*/
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0;
}

    .team-row:last-child {
        border-bottom: none;
    }

.player.progress_bar, .team.progress_bar {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
}

.team.progress_bar {
    align-content: end;
}

.team.image.fill {
    width: 100px;
    margin-right: 10px;
}

.home-container {
    width: auto;
}

.overview_body_bottom {
    margin: 0 30px !important;
}

@media screen and (max-width:1366px) {
    .team.image.fill {
        width: 100%;
        margin-right: 0;
    }

    .team img {
        height: 50px;
    }
}


.padding-bottom-club {
    padding-top: 8px !important;
    padding-bottom: 24px !important;
}


.player_dev_dashboard {
    margin-right: 0px !important;
    padding: 0 !important;
    min-width: 65px !important;
}

.player_dev_popup {    
    top: -100px !important;
    left: 200% !important;
    transform: translateX(-50%) !important;    
}

.team img {
    max-width: 45px !important;
    max-height: 45px !important;
}