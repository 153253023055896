.login_background_image {
    background: url('../../images/banner.png') no-repeat;
    background-size: cover;
}

.loginform_holder {
    padding: 10px 0 !important;
}

.h1-margin-top {
    margin-top: -90px;
}

.p-margin-bottom {
    margin-bottom: 80px !important;
}