.login_background_image {
    background: url('../images/login_banner.jpg') no-repeat;
    background-size: cover;
}

.login_box{
    width: 405px !important;
}

.login_box h1{
    font-family: "Avenir LT Std";
    font-size: 30px;
}

.login_box p{
    color: #a29f9f;
    font-size: 16px;
}

.trademark_bottombar{
    position: fixed;
    line-height: 68px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #000000;
    color: white;
    text-align: center;
    color: #aeb4a9;
    font-size: 15px;
    font-family: Avenir LT 'Avenir LT Std';
}