@charset "utf-8";

/* CSS Document */

* {
    margin: 0px;
    padding: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

    *,
    *::before,
    *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

html {
    width: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Avenir LT Std';
    font-size: 18px;
    color: #020202;
    line-height: 29px;
    background: #fff;
    overflow-x: hidden;
    font-weight: 300;
}

a:hover {
    text-decoration: none !important;
    color: #000;
}

p {
    margin: 0px;
    padding: 0px 0 15px 0;
    font-family: 'Avenir LT Std';
    font-size: 18px;
    color: #020202;
    line-height: 29px;
    font-weight: 300;
}

h1 {
    margin: 0px;
    padding: 0px;
}

h2 {
    margin: 0px;
    padding: 0px;
}

h3 {
    margin: 0px;
    padding: 0px;
}

h4 {
    margin: 0px;
    padding: 0px;
}

h5 {
    margin: 0px;
    padding: 0px;
}

h6 {
    margin: 0px;
    padding: 0px;
}

address,
blockquote,
dd,
div,
dl,
dt,
fieldset,
form,
frame,
frameset {
    margin: 0;
    padding: 0;
    border: 0px;
    outline: none;
}

label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0px;
    padding: 0;
    outline: none;
    border: 0;
}

a {
    text-decoration: none;
    border: 0px;
    outline: none;
    transition: all .5s;
}

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

    a img {
        border: none;
        outline: none;
    }

    a:focus {
        outline: none;
    }

border {
    border-collapse: collapse;
}

hr {
    height: 1px;
    background: #333333;
    border: none;
}

.clear {
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.dropdown-menu.show {
    border-radius: 0 0 8px 8px;
    margin-top: -7px;
}

.season-btn {
    margin-right: 15px;
}

.season-btn-right {
    margin-left: 15px;    
}

.psr .season-btn {
    margin-left: 15px;
    margin-right: 0;
}

.dropdown-toggle.btn {
    background-color: #FFF;
    border-radius: 10px;
    border-color: #cccccc;
    margin: 0 10px 0 0;
    font-size: 14px;
    height: 40px;
    color: #808080;
    font-family: 'Avenir LT Std';
    min-width: 130px;
}

.dropdown-arrow-dark {
    width:20px;
    height:20px;
    float: right;
}

.btn-secondary:hover {
    background-color: #fff !important;
    border-color: #cccccc !important;
    color: #808080 !important;
}

.datatable_top_right .dropdown-menu {
    width: 180px !important;
    background-color: #fff;
    color: #000;
    font-size: 12px;
}

.dropdown-item {
    padding: 0 0 0 0 !important;
    color: #808080 !important;
    font-family: 'Avenir LT Std' !important;
    font-size: 14px !important
}

.datatable_top_right .dropdown-menu button {
    margin: 0;
    width: 100% !important;
    padding: 0 15px !important;
}

.season-inline {
    border: none;
    width: 100px;
    margin: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

    .season-inline span {
        display: table-cell;
        vertical-align: middle;
    }

.season-drop-line {
    width: 100%;
    margin: 0px;
    height: 31px;
    border-width: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 18px;
}

    .season-drop-line span {
        font-size: 14px;
        border-radius: 8px;
        border: 1px dashed #9B9B9B;
        padding: 11px 14px;
    }

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    color: #808080 !important;
    background-color: #fff !important;
    border-color: #9B9B9B !important;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    border-radius: 0;
}

    button:focus {
        /*outline: 1px dotted;*/
        /*outline: 5px auto -webkit-focus-ring-color;*/
    }

    button:active {
        background: none
    }

.dropdown-item.active,
.dropdown-item:active {
    color: inherit !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

.is-invalid {
    border: 1px solid #ffa8a8 !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
        cursor: pointer;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

    [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

::-moz-selection {
    color: #fff;
    background: #8d8c8a;
}

::selection {
    color: #fff;
    background: #8d8c8a;
}

.login_container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background: #1B1B1B;
}

.login_left {
    width: 50%;
    flex-shrink: 0;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_right {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_left img {
    width: 300px;
}

.login_box {
    margin-left: 130px !important;
}

.change-password-caption {
    color: white;
    font-size: 13px;
    padding: 15px 2px 3px 25px;
    border-radius: 13px;
    background-color: rgb(49, 49, 49);
}

    .change-password-caption label {
        margin-left: -10px;
    }

.login_box h1 {
    font-family: 'Avenir 85';
    font-size: 32px;
    color: #FFFFFF;
    padding: 0 0 10px 0;
    letter-spacing: -1.01px;
    line-height: 24px;
}

.login_box p {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Avenir LT Std';
    margin-bottom: 40px;
}

.loginform_holder {
    padding: 60px 0;
}

    .loginform_holder .email_fld {
        width: 100%;
        height: 44px;
        padding: 0 15px;
        background: #ffffff;
        border-radius: 4px 4px 0 0;
        border: none;
        outline: none;
        border-bottom: 1px solid #E7E7E7;
        font-size: 14px;
        color: #606060;
        font-family: 'Avenir LT Std';
    }

    .loginform_holder .password_fld {
        width: 100%;
        height: 44px;
        padding: 0 15px;
        background: #ffffff;
        border-radius: 0 0 4px 4px;
        border: none;
        outline: none;
        font-size: 14px;
        color: #606060;
        font-family: 'Avenir LT Std';
    }

    .loginform_holder input[type=button] {
        margin: 20px 0;
        width: 100%;
        height: 44px;
        background: #c4c4c4;
        text-align: center;
        line-height: 44px;
        color: #000;
        font-family: 'Avenir 85';
        font-size: 15px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 4px;
    }

        .loginform_holder input[type=button]:hover {
            background: #fff;
        }

.pwd_border {
    border-radius: 4px 4px 0 0 !important;
}

.form_field {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 6px;
    top: 12px;
    color: #acacac
}

.login_box {
    width: 350px;
    margin: 0 auto
}

.loginform_holder .email_fld_forget {
    width: 100%;
    height: 44px;
    padding: 0 15px;
    background: #ffffff;
    border-radius: 4px;
    border: none;
    outline: none;
    border-bottom: 1px solid #E7E7E7;
    font-size: 14px;
    color: #606060;
    font-family: 'Avenir LT Std';
}

.chklist_round img {
    width: 50px;
}

.img-user-detail {
    width: 150px !important;
    height: 150px !important;
    display: inline-block;
}

.img-header-thumb {
    height: 50px;
    width: 50px;
}

.img-header-thumb-2 {
    height: 50px;
    width: 50px;
}

.upload-detail {
    position: absolute;
    width: 80px;
    height: 80px;
    opacity: 0;
    z-index: 1;
    top: 40px;
    left: 40px;
}

.photo-upload-caption {
    max-width: 150px;
}

.remove-image {
    background-color: transparent;
    cursor: pointer;
    /*opacity: 0.4;*/
    left: 120px;
    width: 30px;
    height: 30px;
    position: absolute;
    /*display: none;*/
}

.img-user-detail-hover {
    background-color: #000;
    opacity: 0.4;
    left: 0px;
    width: 150px;
    height: 150px;
    position: absolute;
    display: none;
}

.img-setting-club {
    background-color: #000;
    opacity: 0.4;
    left: 0px;
    position: absolute;
    display: none;
}

.upload_btn:hover .img-user-detail-hover, .upload_btn:hover .img-setting-club {
    display: inline-block;
}


/*.scroll-container-custom{
    height: 200px;
    width: 100px;
    padding: 5px;
    border: 1px solid black;
}

.mCSB_draggerRail {
    width: 20px;
}*/


/* width */

::-webkit-scrollbar {
    width: 20px;
}

.MuiTableContainer-root::-webkit-scrollbar {
    width: 11px;
}


/* Track */

.MuiTableContainer-root::-webkit-scrollbar-track {
    box-shadow: inset 0 0 18px #d3d3d3;
    border-radius: 10px;
}


/* Handle */

.MuiTableContainer-root::-webkit-scrollbar-thumb {
    background: #150d0d;
    border-radius: 10px;
}


    /* Handle on hover */

    .MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
        background: #150d0d;
    }

.shadow_container div.MuiTableContainer-root {
    /*max-height: 620px;*/
}

.login_box h3 {
    font-size: 17px;
    color: #FFFFFF;
    font-family: 'Avenir 85';
    padding: 15px 0 20px 0
}

a.back_login_btn {
    display: block;
    width: 100%;
    margin: 20px 0;
    width: 100%;
    height: 44px;
    background: #c4c4c4;
    text-align: center;
    line-height: 44px;
    color: #000;
    font-family: 'Avenir 85';
    font-size: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

    a.back_login_btn:hover {
        background: #fff;
    }

.forget_password a {
    font-size: 14px;
    color: #FFFFFF;
    font-family: 'Avenir LT Std';
    text-decoration: none;
    font-weight: 700;
}

    .forget_password a:hover {
        font-size: 14px;
        color: #FFFFFF;
        font-family: 'Avenir LT Std';
        font-weight: 700;
    }

.login_footer {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    background-color: #2e2e2e;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir LT Std';
    color: #949494
}

.changepassword h1 {
    font-size: 32px;
    padding: 0 0 40px 0
}

.changepassword .loginform_holder {
    padding: 0px;
}

.dashboard_holder {
    display: flex;
    min-height: 100vh
}

.dashboard_left {
    width: 220px;
    /*padding:25px 20px;*/
    background: #101010;
    flex-shrink: 0;
}

.dashboard_right {
    width: 100%;
    background: #f1f1f1
}

.nobordebtm {
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

a.btn_change {
    width: 107px;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    display: flex;
    font-family: 'Avenir 85';
    margin: 0 15px 0 0;
    background: #9B9B9B;
    align-items: center;
}

.team_logo {
    text-align: center;
    font-size: 15px;
    color: #fff;
    font-family: 'Avenir 85';
    margin: 0 0 25px 0;
    padding: 25px 20px;
    width: 220px;
}

    .team_logo img {
        width: 100px;
        margin: 0 auto 10px;
        display: block;
    }

.sidenav {
    padding: 0 20px;
    width: 220px;
}

    .sidenav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .sidenav ul li {
            float: none;
            display: block;
            vertical-align: top;
        }

            .sidenav ul li a {
                font-family: 'Avenir LT Std';
                font-size: 16px;
                display: block;
                line-height: 50px;
                padding: 0 35px;
                margin: 0 0 10px 0;
                color: #fff;
            }

                .sidenav ul li a:hover {
                    background: #2e2e2e;
                    border-radius: 10px;
                }

            .sidenav ul li.active a {
                background: #2e2e2e;
                border-radius: 10px;
            }

.nav_icon {
    width: 25px;
    vertical-align: middle;
    margin: -4px 15px 0 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.overview_top {
    border-bottom: 1px solid #D3D3D3;
    padding: 0 30px;
    display: flex;
    align-items: center;
}

.header_profile_part {
    align-items: center;
}

.season {
    width: 198px;
    padding: 15px 0
}

    .season select {
        width: 100%;
        border: 2px solid #a1a1a1;
        height: 38px;
        border-radius: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: none;
        padding: 0 15px;
        background-repeat: no-repeat;
        background-position: 95% center;
        font-size: 15px;
    }

.dasboad_header_right {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.dasboad_header_search {
    width: 325px;
    padding: 15px 0;
    margin: 0 15px 0 0;
    position: relative;
}

    .dasboad_header_search .searchbox {
        width: 100%;
        background: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        padding: 0 15px;
        height: 40px;
        outline: none;
        font-size: 14px;
        font-family: "Avenir LT Std";
        color: #606060;
        font-size: 14px;
        letter-spacing: -0.34px;
    }

    .dasboad_header_search .searcsubmit {
        width: 38px;
        height: 38px;
        border: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 15px;
    }

.dropdown-top-left {
    font-size: 12px;
}

    .dropdown-top-left img {
        max-width: 25%;
    }

.profile_box_holder {
    border-left: 1px solid #D3D3D3;
    padding: 15px;
    position: relative;
    cursor: pointer;
    width: 400px;
}

.fullright_sec {
    margin-left: inherit;
    justify-content: space-between;
    width: 100%;
}

.logout-text {
    color: #555555 !important;
}

.profile_name {
    padding-right: 5px;
    font-family: "Avenir LT Std";
    position: relative;
    z-index: 9
}

.profile_box_holder a {
    display: flex;
    width: 100%;
    align-items: center;
}

.modal-user {
    overflow-y: hidden !important;
}

.profile_img {
    width: 50px;
    flex-shrink: 0;
    margin: 0 15px 0 0;
    position: relative;
    z-index: 9
}

.name {
    font-size: 14px;
    color: #515151;
    line-height: 19px;
    font-family: 'Avenir 85';
    margin-top: 0px;
    letter-spacing: -0.1px;
}

.designation {
    font-size: 14px;
    color: #606060;
    letter-spacing: -0.29px;
    line-height: 19px;
}

.profile_icon {
    margin: 0 0 0 30px;
    position: relative;
    z-index: 9
}

.profile_dropdown {
    background: #fff;
    padding: 15px 15px 5px 15px;
    border-top: 1px solid #D3D3D3;
    position: absolute;
    width: 100%;
    top: 0;
    display: none;
    left: 0px;
    z-index: 1;
    padding-top: 79px;
    background: #FBFBFB;
    box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.10);
    border-radius: 0 0 8px 8px;
}

    .profile_dropdown ul {
        margin: 0px;
        padding: 10px 0 0 0;
        list-style: none;
        border-top: 1px solid #D3D3D3;
    }

        .profile_dropdown ul li {
            float: none;
            display: block;
        }

            .profile_dropdown ul li a {
                display: block;
                font-size: 14px;
                color: #555555;
                margin: 0 0 15px 0;
                line-height: 30px;
            }

a:hover,
a:focus,
a:active {
    text-decoration: none;
    /*border: 0px;*/
    outline: none;
    transition: all .5s;
    color: #606060;
}

.pronav_icon {
    display: inline-block;
    vertical-align: top;
    width: 25px;
    margin: 0 15px 0 0
}

.overview_body {
    /*padding: 50px;*/
}

.overview_body_top {
    background: #FFFFFF;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 25px;
    padding-top: 15px;
    /*display: flex;*/
    /*align-items: center;*/
    margin: 30px !important;
}

.overview_body_top_left {
    display: flex;
    align-items: center;
    text-align: center;
}

.total_box {
    padding: 0 45px;
    border-right: 1px solid #E7E7E7;
}

    .total_box .total {
        font-size: 16px;
        color: #515151;
        padding: 0 0 10px 0;
        font-family: 'Avenir LT Std';
    }

.total_num {
    font-size: 24px;
    color: #9B9B9B;
    font-family: 'Avenir 85';
}

.overview_filter_section {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0
}

    .overview_filter_section label {
        font-size: 15px;
        color: #555555;
        padding: 0 20px 0 0;
        flex-shrink: 0;
        font-family: 'Avenir LT Std';
        margin-left: 5px;
        margin-bottom: 0px !important;
    }

    .overview_filter_section select {
        width: 150px;
        border: 1px solid #9B9B9B;
        height: 40px;
        border-radius: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: none;
        padding: 0 10px;
        background-repeat: no-repeat;
        background-position: 95% center !important;
        font-size: 14px;
        color: #606060;
        background: url('../images/arrow_down.png') no-repeat center center;
        font-family: 'Avenir LT Std';
    }

.input_right_logo {
    background-repeat: no-repeat;
    background-position: 95% center !important;
    background: url('../images/arrow_down.png') no-repeat center center;
}

.react-select-drop__control {
    width: 180px;
    border-radius: 10px;
    height: 10px;
    align-content: center;
    font-family: 'Avenir LT Std' !important;
    font-size: 14px !important;
    background: #E7E7E7;
    text-align: center !important;
}

.react-select-drop-container {
    width: 200px !important;
}

.overview_content_box {
    background: #FFFFFF;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    /*overflow: hidden;*/
    margin-bottom: 30px;
    padding-top: 1px;
}

.overview_panel_adjustment {
    height: 450px;
    max-height: 450px;
}

.overview_content_box h2 {
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir LT Std';
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 20px;
    padding-top: 5px;
    margin-bottom: 15px;
    margin: 15px;
    padding-top: 5px;
    font-weight: 900;
    letter-spacing: -0.11px;
    line-height: 19px;
}

.first_box_top {
    display: flex;
    justify-content: space-between;
}

.first_box_top_cn {
    width: 33.33%;
    padding: 15px 15px;
    border-right: 1px solid #D3D3D3;
    text-align: center;
}

#app {
    margin-top: 40px;
}

#progressInput {
    margin: 20px auto;
    width: 30%;
}

.img-dashboard-thumb {
    height: 35px;
    width: 35px;
}

.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ddd;
}

.circle-progress {
    stroke: #000;
    stroke-linecap: square;
    stroke-linejoin: round;
}

.circle-text {
    font-size: 15px;
    font-weight: bold;
    fill: #000;
}

    .circle-text.extra {
        font-size: 10px;
        font-weight: inherit;
        fill: #c4c4c4;
    }

.first_box_top_cn:last-child {
    border: none;
}

.over_top_icon {
    margin: 0 0 15px 0
}

    .over_top_icon img {
        width: 60px;
    }

.progress-ring__circle {
    stroke-dasharray: 10 20;
}

.first_box_top_cn h3 {
    font-size: 15px;
    color: #606060;
    font-family: 'Avenir 85';
    padding: 0 0 10px 0
}

.first_box_top_cn h4 {
    font-size: 14px;
    color: #606060;
    padding: 0;
    font-family: 'Avenir LT Std';
}

.first_box_top_cn h5 {
    font-size: 12px;
    color: #9B9B9B;
}

.first_box_top_cn h6 {
    font-size: 24px;
    color: #606061;
    font-family: 'Avenir 85';
}

.first_box_bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    background: #F7F7F7;
    padding: 15px 0;
    height: 44%;
}

.cn_top_tx {
    font-size: 24px;
    color: #1B1B1B;
    font-family: 'Avenir 85' !important;
}

span.up_ar {
    font-size: 12px;
    padding-left: 5px;
    font-family: 'Avenir LT Std';
    height: 8px;
    width: 12px;
}

span.up_ar_left {
    padding-right: 5px;
}

th span {
    font-family: "Avenir LT Std" !important;
}

td.MuiTableCell-root {
    font-family: "Avenir LT Std" !important;
}

.trainer_lg {
    width: 35px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
}

span.up_ar img {
    margin: 0 5px 0 0
}

.MuiTableCell-root img.rounded-circle {
    width: 35px;
    height: 35px;
}

.rounded-circle {
    border-radius: 50% !important;
}

.upload-picture-box {
    width: 150px !important;
    height: 150px;
    border-radius: 80px;
    object-fit: cover;
}

.badge-danger-extend {
    border-radius: 10px !important;
    padding-left: 9px !important;
    padding-right: 9px !important;
    margin-left: 10px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 20px;
    line-height: 20px !important
}

.para_cn p {
    font-size: 14px;
    color: #606060;
    padding: 5px 0px 15px 30px;
    width: 112px;
    letter-spacing: -0.34px;
    line-height: 19px;
}

.cn_top_tx {
    padding-top: 15px;
    padding-bottom: 0;
}

.can_top_dif {
    font-size: 24px;
    color: #9B9B9B;
}

.player_table_row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E7E7E7;
    padding: 11px 0;
    /*width:80px;*/
}

    .player_table_row:last-child {
        border-bottom: none;
    }

.player_image {
    width: 40px;
    flex-shrink: 0;
    margin: 0 5px 0 0
}

.player_name {
    font-size: 14px;
    color: #606060;
    flex-shrink: 0;
    margin: 0 12px 0 0px;
    min-width: 100px;
    /*width: 130px;*/
    font-family: "Avenir LT Std" !important;
}

.left-row {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding-left: 0.2rem;
}

.report-row {
    width: 16%;
}

td .progress_bar {
    margin-left: inherit;
    max-width: 170px;
}

.progress_bar {
    width: 10%;
    position: relative;
    padding: 0 40px 0 0;
    margin-left: auto;
    min-width: 120px;
}

td .progress_bar_full {
    margin-left: inherit;
    display: inline-block;
}

.progress_bar_full {
    width: 80%;
    position: relative;
    padding: 0 30px 0 0;
    margin-left: auto;
    min-width: 120px;
}

.player_stat_part {
    padding: 0 15px 30px;
}

    .player_stat_part .progress {
        height: 11px;
        background: #F1F1F1;
        border-radius: 50px;
    }

    .player_stat_part .progress-bar {
        background: #2E2E2E;
    }

    .player_stat_part .progress span {
        position: absolute;
        right: 0px;
        font-size: 12px;
        color: #606060;
        width: 20px;
    }

.progress_complete img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 8px;
}

.progress_complete {
    font-size: 12px;
    color: #606060;
    text-align: right;
    margin-right: -30px;
}

.trainer_lg {
    width: 35px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
    text-align: left;
}

.traincode {
    flex-shrink: 0;
    margin: 0 10px 0 0;
    font-size: 14px;
    color: #606060;
    padding: 0 0 0 10px;
    width: 120px;
}

.trainer_drop_up, .player_drop_up, .team_drop_up {
    background: #FBFBFB;
    box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 10px;
    width: 170px;
}

.drop_up_top {
    display: flex;
    align-items: center;
}

.trainer_pic {
    width: 30px;
    margin: 0 12px 0 0;
    flex-shrink: 0;
}

.trainer_name_drop_up {
    font-size: 12px;
    color: #515151;
    line-height: 20px;
}

.trainer_code_drop_up {
    font-size: 12px;
    color: #C4C4C4;
    line-height: 20px;
}

.drop_up_bottom {
    padding: 10px 0 0
}

.report_cn {
    display: flex;
    align-items: center;
}

.report_left {
    font-size: 12px;
    color: #515151;
}

.report_right {
    margin-left: auto;
    font-size: 12px;
    color: #1B1B1B;
    font-family: 'Avenir 85';
}

.trainer_drop_up {
    position: absolute;
    top: -140px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}

.no_overflow {
    overflow: visible;
}

.progress_bar:hover .trainer_drop_up {
    display: block;
}

.player_drop_up, .team_drop_up {
    position: absolute;
    left: 0;
    display: none;
}

.round_progress:hover .player_drop_up {
    top: -20%;
    display: block;
}

.round_progress:hover .team_drop_up {
    top: -25%;
    display: block;
}

.footer {
    width: 100%;
    left: 0px;
    bottom: 0px;
    background-color: #2e2e2e;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir LT Std';
    color: #949494;
}

.mobile_menu {
    display: none;
}

.closebtn {
    display: none;
}

.shadow_container {
    background: #FFFFFF;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 15px;
    margin: 30px 30px 0 30px;
    font-family: 'Avenir LT Std';
}

.datatable_top {
    border-bottom: 1px solid #E7E7E7;
    padding: 0 0 12px 0;
    display: flex;
    align-items: center;
}

.datatable_top_left {
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir 85';
    display: table;
}

.datatable_top_right {
    margin-left: auto;
    display: flex;
}

a.btn_remove {
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #9B9B9B;
    display: block;
    font-family: 'Avenir 85';
    margin: 0 15px 0 0;
    display: flex;
    align-items: center;
}

.searchbox-container {
    display: none !important;
    background-color: white;
    top: 20px;
    border-radius: 10px;
    width: 325px;    
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.05);
    border: 0px solid #E7E7E7;
    font-family: 'Avenir LT Std';
    color: #606060;
}

.searchbox-sub-container {
    border-bottom: 1px solid #E7E7E7;
    margin: 0 15px;
    font-size: 14px;
    line-height: 40px;
    padding: 10px 0;
}

    .searchbox-sub-container a, .searchbox-sub-container a:hover {
        color: #606060;
    }

.searchbox-cell-left-name {
    margin-left: 5px;
}

.searchbox-cell-left {
    display: inline-block;
    width: 80%;
}

.searchbox-cell-right {
    display: inline-block;
    text-align: right;
    width: 20%;
}

.add_pay_section {
    padding: 15px 0;
}

.add_pay_section {
    display: flex;
}

.nopadbtm {
    padding-bottom: 0;
}

.dasboad_header_search {
    width: 325px;
    padding: 15px 0;
    margin: 0 15px 0 0;
    position: relative;
}

.add_pay_section .dasboad_header_search {
    width: 100%;
}

.searchbox ::placeholder {
    color: #9B9B9B;
}

.searchbox ::-moz-placeholder {
    color: #9B9B9B;
}

.searchbox :-ms-input-placeholder {
    color: #9B9B9B;
}

.dasboad_header_search .searchbox {
    width: 100%;
    background: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 0 15px;
    height: 40px;
    outline: none;
    color: #b8b8b8;
    font-size: 15px;
}

.dasboad_header_search .searcsubmit {
    background: url('../images//search_icon.png') no-repeat center center;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 15px;
}

.datatable_bottom {
    padding: 0px 0;
}

.add_pay_section {
    padding: 15px 0;
}

.change-player-container .MuiTableContainer-root {
    overflow-x: hidden;
}

h2.add-move-trainer {
    border-bottom: none;
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir 85';
    padding: 10px 0 20px 0;
}

.btn_change img {
    width: 20px;
    margin: -3px 10px 0 10px;
    vertical-align: middle;
}

.btn_remove img {
    width: 20px;
    margin: auto;
    vertical-align: middle;
    filter: brightness(0.5);
}

.btn_remove.btn_print img {
    filter: brightness(0.8);
}

a.btn_remove.btn_print {
    border: 1px solid #9B9B9B;
}

.change-add-top {
    border-bottom: 1px solid #E7E7E7;
    display: inline;
}

a.btn_adnew {
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #2e2e2e;
    border-radius: 10px;
    line-height: 35px;
    font-size: 14px;
    color: #fff;
    display: block;
    font-family: 'Avenir 85';
    background: #2e2e2e
}

    a.btn_adnew:hover {
        color: #fff;
    }

    a.btn_adnew img {
        /*margin: -3px 5px 0 0*/
    }

a.btn_change:hover {
    color: #fff;
}

a.btn_remove:hover {
    color: #9B9B9B;
}

    a.btn_remove:hover img {
        /*filter: brightness(100);*/
    }

.datatable_bottom {
    padding: 15px 0
}

    .datatable_bottom .dataTables_length {
        display: none;
    }

    .datatable_bottom .dataTables_filter {
        display: none;
    }

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 1px;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
}

.datatable_bottom .table-bordered td,
.table-bordered th {
    border: none;
}

table.table-bordered.dataTable th {
    font-size: 14px;
    color: #9B9B9B;
    font-weight: normal;
    vertical-align: middle;
}

table.table-bordered.dataTable td {
    font-size: 14px;
    color: #606060;
    vertical-align: middle;
    border-bottom-width: 1px !important;
    border-bottom: 1px solid #dee2e6 !important
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 1.0);
}

.checkbox_tab:after {
    display: none !important;
}

.checkbox_tab:before {
    display: none !important
}

.progress-bar {
    background: #2E2E2E !important;
}

.progress {
    height: 0.7rem !important;
    background: #F1F1F1;
    border-radius: 10px !important;
}

    .progress span {
        position: absolute;
        right: 0px;
        font-size: 12px;
        color: #606060;
        width: 20px;
    }

a.btn_view {
    background: #9B9B9B;
    border-radius: 10px;
    font-size: 14px;
    color: #FFFFFF;
    display: block;
    width: 80px;
    height: 40px;
    line-height: 40px;
    font-family: 'Avenir 85';
    text-align: center;
    float: right;
}

a.btn_reinvite {
    height: 40px;
    text-align: center;
    border: 1px solid #2e2e2e;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    display: block;
    font-family: 'Avenir 85';
    background: #2e2e2e
}

a.btn_reinvite_disabled {
    height: 40px;
    text-align: center;
    border: 1px solid #e0e0e0 !important;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    display: block;
    font-family: 'Avenir 85';
    background: #e0e0e0 !important;
    pointer-events: none;
}

a.detail-manager-link {
    color: #343a40 !important;
    font-size: 30px;
    margin-top: -5px;
}

tbody .MuiTableCell-root {
    padding-top: 10px;
    padding-bottom: 10px
}

.select-managers {
    width: 130px;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #9B9B9B;
    display: block;
    font-family: 'Avenir 85';
    margin: 0 10px 0 0;
}

.role_cn {
    border: 1px solid #a1a1a1;
    height: 38px;
    border-radius: 10px;
    outline: none;
    padding: 0 15px;
    font-size: 15px;
    max-width: 160px;
    margin: 0 10px 0 0;
    color: #9B9B9B;
    line-height: 38px;
}

.backbtn {
    margin: 0 5px 0 0;
    font-size: 30px;
    cursor: pointer;
    text-decoration: none;
}

.chkholder {
    display: list-item;
    position: relative;
    /* padding-left: 15px; */
    margin-bottom: 0;
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .chkholder input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

.checkmark {
    position: absolute;
    left: 0;
    border: 1px solid #9B9B9B;
    border-radius: 5px;
    background: transparent;
    width: 20px;
    height: 20px;
}

.chkholder input:checked ~ .checkmark {
    /*background: #2E2E2E;;*/
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.chkholder input:checked ~ .checkmark:after {
    display: block;
}

.chkholder .checkmark:after {
    background: #2E2E2E;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    top: 1px;
    left: 1px;
}


.chkholder-select {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .chkholder-select input {
        font-family: 'Avenir LT Std' !important;
        font-size: 14px;
        position: absolute;
        opacity: 0;
        /*cursor: pointer;*/
        height: 0;
        width: 0;
    }

        .chkholder-select input:checked ~ .checkmark-select {
            /*background: #2E2E2E;*/
        }

.checkmark-select {
    position: absolute;
    top: 0px;
    left: 0;
    background: transparent;
    border: 1px solid #9B9B9B;
    border-radius: 5px;
    border-radius: 5px;
    background: transparent;
    width: 24px;
    height: 24px;
}

    .checkmark-select:after {
        content: "";
        position: absolute;
        display: none;
    }

.chkholder-select input:checked ~ .checkmark-select:after {
    display: block;
}

.chkholder-select .checkmark-select:after {
    background: #2E2E2E;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    top: 3px;
    left: 3px;
}

.label-select {
    display: block;
    text-align: center;
    font-family: 'Avenir LT Std' !important;
    cursor: pointer;
    font-size: 14px;
}


.dataTables_info {
    font-size: 14px;
    color: #606060;
}

.page-item.active .page-link {
    background: #EFEFEF;
    border: 1px solid #BBBBBB;
    font-size: 14px;
    color: #2E2E2E;
}

.pagination {
    font-size: 14px;
    color: #2E2E2E;
}

a.btn_view_train,
a.btn_view_player {
    display: inline-block;
    margin: 0 0 0 10px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    background: #9B9B9B;
    border-radius: 10px;
    font-family: 'Avenir 85';
    text-align: center;
    color: #fff
}

a.btn_view_train {
    background: #9B9B9B;
}

a.btn_view_player {
    background: #555555;
}

.img_brance img {
    display: inline-block;
    width: 35px;
    vertical-align: top
}

    .img_brance img:nth-child(1) {
        position: relative;
        z-index: 4
    }

    .img_brance img:nth-child(2) {
        position: relative;
        margin-left: -25px;
        z-index: 3
    }

    .img_brance img:nth-child(3) {
        position: relative;
        margin-left: -25px;
        z-index: 2
    }

.img_brance {
    margin-right: 8px;
}

.nopadding {
    padding: 0
}

td span.up_ar {
    vertical-align: middle;
    color: #85B94F;
}

td span.down_ar {
    vertical-align: middle;
    color: #D0021B;
}

.floatright {
    float: right;
}

.backto a {
    font-size: 17px;
    color: #515151;
    font-family: 'Avenir 85';
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: -30px;
    display: inline-block;
    vertical-align: top;
}

    .backto a:hover {
        color: #515151;
        font-family: 'Avenir 85';
    }

.details_top {
    position: relative;
    display: flex;
}

.details_img {
    width: 100px;
    flex-shrink: 0;
    margin: 0 15px 0 0
}

.details_name {
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 15px;
    font-size: 20px;
    color: #515151;
    font-family: 'Avenir 85';
    width: 100%;
    margin-bottom: 10px;
}

.details_box {
    width: 100%
}

.train_top {
    font-size: 14px;
    color: #606060;
}

.noborder_mar {
    border: none;
    padding-bottom: 10px;
}

.trains_detab {
    margin-top: 15px;
}

    .trains_detab td {
        font-size: 14px;
        color: #606060;
        line-height: 30px;
    }
    .trains_detab .birthdate {
        display: none ;
    }

.right_cntb {
    text-align: left !important;
}

.dt_gap {
    padding: 0 10px;
    text-align: center;
}

.remove_details_pg {
    position: absolute;
    top: -5px;
    right: 0px;
}

a.btn_remove.text-center.season-btn img {
    width: 7%;
    position: absolute;
    right: 10px;
    top: 18px;
    transition: 0.5s;
}

.completed-side-report {
    min-width: 100px;
}

    .completed-side-report.container-box {
        display: flex;
    }

    .completed-side-report.left-side {
        display: inline;
    }

    .completed-side-report.right-side {
    }

    .completed-side-report.right {
        display: inline;
        padding-left: 5px
    }

.completed-side-dashboard {
    display: flex;
    text-align: right;
}

.completed-dashboard-left {
    min-width: 120px;
    font-size: 12px;
    display: inline;
    margin-top: auto;
}

.completed-dashboard-right {
    text-align: right;
    margin-left: 10px;
    display: inline;
}

.accro_img .rounded-circle {
    height: 50px;
    width: 50px;
}

.completed-side-report.score {
    text-align: right;
    font-weight: bold;
    font-size: 24px;
    align-self: center;
}

.remove_details_pg a.btn_remove {
    margin-right: 0
}

.details_table_left td {
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir 85';
}

.details_table_left input[type=text] {
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #E7E7E7;
    outline: none;
    font-size: 14px;
    color: #606060;
    font-family: 'Avenir LT Std';
    width: 100%;
    border-radius: 10px;
}

.dashbord-min-height {
    min-height: 400px;
    max-height: 400px;
}

table.details_table_left {
    width: 100%
}

    table.details_table_left .dt_gap {
        width: 45px;
    }

.allborder {
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    padding: 5px 10px;
    border-bottom: 1px solid #9B9B9B !important;
    position: relative;
}

    .allborder:after {
        content: '';
        position: absolute;
        width: 13px;
        height: 15px;
        right: 10px;
        top: 11px;
    }

a.btn_update {
    width: 140px;
    height: 40px;
    background: #a4a4a4;
    border-radius: 10px;
    text-align: center;
    line-height: 40px;
    display: block;
    font-size: 14px;
    color: #FFFFFF;
    margin: 30px 0;
    float: right;
}

    a.btn_update:hover {
        width: 140px;
        height: 40px;
        background: #a4a4a4;
        border-radius: 10px;
        text-align: center;
        line-height: 40px;
        display: block;
        font-size: 14px;
        color: #FFFFFF;
        margin: 30px 0;
        float: right;
    }

.table.dataTable {
    margin-top: 0 !important
}

.toggle_sw {
    margin: 0 0 0 25px;
    border-left: 1px solid #E7E7E7;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
}

    .toggle_sw .switch {
        height: 24px;
        width: 50px;
        margin-top: 3px;
    }

    .toggle_sw input:checked + .slider {
        background-color: #2e2e2e;
    }

    .toggle_sw .slider:before {
        width: 20px;
        height: 20px;
    }

    .toggle_sw .slider:before {
        left: 1px;
        bottom: 2px;
    }

    .toggle_sw span {
        padding: 0 7px;
        font-size: 14px;
        color: #9B9B9B;
        line-height: 18px;
    }

        .toggle_sw span.active_tg {
            color: #606060;
        }

.accordion {
    margin: 34px 0
}

.drawer {
    width: 100%;
    margin-bottom: 0px;
}

.accordion-item {
}

.accordion-item-active .accordion-header {
}

.accordion-item-active .accordion-header-icon {
    color: #fff;
}

.accordion-item-active .accordion-header .accrodian_header {
    color: #231f20;
}

.accordion-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 5%;
    background: white;
    border-radius: 8px;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
}

    .accordion-header .accordion-content {
        display: block;
        background: transparent;
        border: none;
    }

    .accordion-header .accrodian_header {
        font-size: 16px;
        margin: 0;
        line-height: 1.3em;
        color: #999999;
        width: 100%
    }

.accordion-content {
    display: none;
}

    .accordion-content p {
        margin: 0;
        margin-bottom: 0;
        padding-bottom: 0px;
    }

.accordion-header-icon {
    float: right;
    color: #000;
    font-size: 18px;
    vertical-align: middle;
    padding: 0 10px;
}

    .accordion-header-icon.accordion-header-icon-active {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        color: #000;
    }

.accro_hd {
    display: flex;
    width: 100%;
}

.accro_img {
    width: 50px;
    margin: 0 20px 0 0;
    flex-shrink: 0
}

.accro_details {
    width: 100%;
    margin: auto;
}

.accro_name {
    font-size: 14px;
    color: #515151;
    font-family: 'Avenir 85';
    margin-bottom: 15px;
}

.accro_progress_table table {
    width: 100%;
    font-size: 14px;
    color: #606060;
    line-height: 40px;
}

    .accro_progress_table table td:first-child {
        width: 100px;
    }

.status {
    position: relative;
}

.accrod_date {
    font-size: 12px;
    color: #ABABAB;
    margin-bottom: 8px;
}

.accro_de {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.rat_name {
    text-align: left;
    font-size: 14px;
    color: #606060;
}

.rat_number {
    margin-left: auto;
    font-size: 12px;
    color: #b0b0b0;
    display: flex;
}

    .rat_number span {
        border: 1px solid #2E2E2E;
        background-color: #000;
        border-radius: 7px;
        padding: 5px 15px;
        color: #fff;
        width: 40px;
        text-align: center;
    }

.accro_comment {
    background: #f1f1f1;
    border-radius: 8px;
    margin: 10px 0px;
    padding: 15px;
    color: #212529;    
}
    .accro_comment h3 {
        font-size: 12px;
        color: #ABABAB;
    }
.no_display{        
    display: none;
}
    

.accro_feedback {
    background: #f1f1f1;
    border-radius: 8px;
    margin: 20px 15px;
    padding: 15px;
}

    .accro_feedback h3 {
        font-size: 12px;
        color: #ABABAB;
        padding: 0 0 12px 0
    }

    .accro_feedback p {
        font-size: 14px;
        color: #606060;
        padding: 0;
    }

.accordion-content-wrapper {
    background: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.accrd_detils_repet {
    padding-top: 15px;
}

    .accrd_detils_repet:last-child {
        border-bottom: none;
    }

.position_select {
    min-width: 225px;
    width: 100%;
    border: 1px solid #a1a1a1;
    height: 38px;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 14px;
    margin-top: 10px;
}

.over_score {
    margin-left: auto;
    font-size: 36px;
    color: #1B1B1B;
    font-family: 'Avenir 85';
}

.overall_progress {
    display: flex;
    width: 100%;
    align-items: center;
}

.over_t {
    flex-shrink: 0;
    font-size: 14px;
    color: #606060;
    padding: 0 15px 0 0;
}

.over_pro {
    width: 100%
}

.overall_progress {
    padding: 20px 0;
    border-bottom: 1px solid #E7E7E7;
    margin-bottom: 15px;
    border-top: 1px solid #E7E7E7;
}

.noborder {
    border: none;
}

.overall_toggle {
    display: none;
}

.arro_icn {
    color: #000;
    font-size: 18px;
}

.backbtn {
    margin: 0 10px 0 0
}

.datatable_top_left .traincode {
    font-size: 14px;
}

.badge-danger {
    font-size: 14px;
    vertical-align: middle !important;
}

thead.MuiTableHead-root span {
    color: #9B9B9B;
}

a:hover {
    text-decoration: none !important;
}

.custom-input-icon {
    position: absolute;
    left: auto;
    right: 10px;
    text-indent: 32px;
    margin-top: 15px;
    color: #a5a5a5
}

.division {
    display: flex;
    flex-shrink: 0;
    align-items: center;
}

.name_divi {
    padding: 0 10px 0 0;
    font-size: 14px;
    color: #606060;
}

.num_divi {
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 0 12px;
    font-size: 14px;
    color: #606060;
    line-height: 38px;
    margin: 0 15px 0 0
}

.datatable_bottom td select {
    width: 137px;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    outline: none;
    border: 1px solid #a1a1a1;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 14px;
}

.page-link:hover {
    border: 1px solid #BBBBBB;
    color: #6c757d;
    outline: none;
}

.page-link {
    color: #6c757d;
    outline: none;
}

    .page-link:active,
    .page-link:focus {
        border: none;
        border: 1px solid #BBBBBB;
        color: #6c757d;
        outline: none;
        box-shadow: none;
    }

.deletemodal .modal-content {
    border-radius: 12px;
    padding: 15px;
}

.trash_icon img {
    width: 60px;
}

.report-skill-column-border-left, .report-skill-column-border-right {
    align-items: center;
    display: flex;
}

.report-skill-small-thumb {
    height: 30px;
}

.report-skill-row-border-top {
    border-top: solid 1px #b0b0b0;
    border-bottom: solid 1px #b0b0b0;
    height: 40px;
}

.report-skill-row {
    margin-top: 10px;
}

.accro_progress_table td .progress_bar_full {
    max-width: 80%;
}

.skill-expand-arrow {
    font-size: 40px;
    color: #000;
    padding-left: 10px;
}

td .progress_bar_full {
    margin-left: inherit;
    /*max-width: 170px;*/
}

.progress_bar_full {
    width: 100%;
    position: relative;
    padding: 0 30px 0 0;
    margin-left: auto;
    min-width: 120px;
}

.search_payer_tr {
    display: flex;
    margin: 0 5px 0 0;
}

.newdrpbx {
    position: absolute;
    width: 95%;
    top: 86%;
    left: 10px;
    display: none;
    z-index: 9999;
    background: #fff;
}

    .newdrpbx ul {
        list-style: none;
        border-left: 1px solid #9B9B9B;
        border-right: 1px solid #9B9B9B;
        border-bottom: 1px solid #9B9B9B;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }

        .newdrpbx ul li {
            padding: 12px 18px;
            display: block;
            font-family: 'Avenir 85';
            border-top: 1px solid #9B9B9B;
        }

            .newdrpbx ul li.active {
                color: #fff;
                background: #000 url('../images//Checklist_Small.png') 95% 50% no-repeat;
                background-size: 10%;
            }

.newteammodal select {
    width: 100%;
    border: 1px solid #a1a1a1;
    height: 38px;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    /*background-image: url(assets/images/arrow_bottom.png);*/
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 14px;
    margin-bottom: 10px;
}

.bgimg {
    padding-left: 33px !important;
    /*background-image: url(assets/images/over_top_icon_1.png), url(assets/images/arrow_bottom.png) !important;*/
    background-size: 16%, auto !important;
    background-position: 5% 50%, 95% 50% !important;
    background-repeat: no-repeat, no-repeat !important;
    cursor: pointer !important;
}

input[type=text] {
    width: 100%;
    height: 40px;
    border: 1px solid #9B9B9B;
    border-radius: 10px 0 0 10px;
    outline: none;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 400;
}

.search_payer_tr button[type=submit] {
    width: 40px;
    height: 40px;
    border-radius: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    background: #2e2e2e;
    max-width: 40px;
    padding: 0 10px;
    flex-shrink: 0;
}

.report-skill-header-container {
    background: #f1f1f1;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    padding: 20px;
    text-align: left;
}

.report-skill-header-divider {
    background: #b0b0b0;
    border: solid 1px;
    margin: 0 10px 0 10px;
}

.report-skill-center-divider {
    font-size: 20px;
}

.table-report-detail {
    /*text-align: center;*/
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #b0b0b0;
    font-size: 12px;
    width: 100%;
}

    .table-report-detail tr td {
        padding: 0 15px;
    }

.report-skill-score {
    text-align: right;
    margin-right: auto;
    width: 100%;
}

.report-skill-title-col {
    padding-left: 20px;
}

.deletemodal h3 {
    font-size: 18px;
    color: #515151;
    font-family: 'Avenir 85';
    padding: 10px 0 15px
}

.deletemodal p {
    font-size: 14px;
    color: #606060;
}

.delete_btn_holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

a.btn_cancel {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    color: #9B9B9B;
    font-family: 'Avenir 85';
    padding: 0 20px;
    margin: 0 5px;
    height: 40px;
    line-height: 40px;
}

    a.btn_cancel:hover {
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        color: #9B9B9B;
        font-family: 'Avenir 85';
        padding: 0 20px;
        margin: 0 5px;
        height: 40px;
        line-height: 40px;
    }

a.btn_remove_modal {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-family: 'Avenir 85';
    padding: 0 20px;
    margin: 0 5px;
    background: #101010;
    border-radius: 10px;
    height: 40px;
    line-height: 40px;
}

.trainermodal h2 {
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir 85';
    border-bottom: 1px solid #E7E7E7;
    padding: 10px 0 20px 0
}

.add_pay_section {
    display: flex;
}

.add_pic_section {
    margin-right: 15px;
    flex-shrink: 0
}

.add_details {
    width: 100%;
    padding: 0 0 0 30px;
}

.details_table_add input[type=text] {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DAD9D9;
    border-radius: 7px;
    padding: 0 10px;
    height: 40px;
    margin-bottom: 10px;
    font-size: 14px;
    outline: none;
}

.details_table_add .position_select {
    margin-top: 0;
    margin-bottom: 10px;
}

.details_table_add td {
    font-size: 14px;
}

.add_pay_section {
    padding: 15px 0
}

.add_pic_section p small {
    font-size: 10px;
    color: #ABABAB;
    line-height: 1.4
}

.add_pic_section p {
    line-height: 16px;
}

.upload_btn {
    /*margin-bottom: 10px;*/
}

.details_table_add {
    width: 100%
}

.trainermodal .modal-content {
    border-radius: 12px;
    padding: 0px 30px;
}

a.btn_add_modal {
    border: 1px solid #2e2e2e;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-family: 'Avenir 85';
    padding: 0 20px;
    margin: 0 5px;
    background: #2e2e2e;
    border-radius: 10px;
    height: 40px;
    line-height: 40px;
}

    a.btn_add_modal:hover {
        border: 1px solid #2e2e2e;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        color: #fff;
        font-family: 'Avenir 85';
        padding: 0 20px;
        margin: 0 5px;
        background: #2e2e2e;
        border-radius: 10px;
        height: 40px;
        line-height: 40px;
    }

.btn_all {
    margin: 0 5px 0 0;
}

.btn_open {
    margin: 0 5px 0 0;
}

.btn_grp_tr a {
    width: 100%;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #9B9B9B;
    display: block;
    font-family: 'Avenir 85';
    display: flex;
    align-items: center;
    padding: 0 15px;
}

    .btn_grp_tr a:hover {
        background: #2E2E2E;
        color: #fff !important;
    }

.btn_grp_tr_active a {
    width: 100%;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    display: block;
    font-family: 'Avenir 85';
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: #2E2E2E;
}

    .btn_grp_tr_active a:hover {
        background: #2E2E2E;
        color: #fff !important;
    }

.trainermodal .delete_btn_holder {
    justify-content: flex-end;
    margin-top: 15px;
}

.assign_top .trainer_lg {
    width: 40px;
}

.assign_top .traincode {
    font-size: 15px;
}

.assignmodal .modal-content {
    border-radius: 12px;
    padding: 15px;
}

.assignmodal td {
    vertical-align: middle;
}

.as_btn_hold a {
    display: inline-block;
    vertical-align: top;
}

a.btn_added {
    width: 80px;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #9B9B9B;
    display: inline-block;
    font-family: 'Avenir 85';
    margin: 0 0 0 0
}

.assignmodal .delete_btn_holder {
    justify-content: flex-end;
    margin-top: 5px;
    padding: 15px 0 0;
    border-top: 1px solid #dee2e6
}

.setting_nav {
}

    .setting_nav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .setting_nav ul li {
            float: none;
            display: block;
        }

            .setting_nav ul li a {
                font-size: 14px;
                color: #606060;
                line-height: 50px;
                padding: 0 15px;
                border-top: 1px solid #E7E7E7;
                display: block;
            }

            .setting_nav ul li:first-child a {
                border: none;
            }

            .setting_nav ul li a:hover {
                background: #2E2E2E;
                border-radius: 6px;
                color: #fff;
            }

            .setting_nav ul li.active a {
                background: #2E2E2E;
                border-radius: 6px;
                color: #fff;
                background-repeat: no-repeat;
                background-position: 95% center
            }

a.btn_save {
    width: 80px;
    height: 40px;
    text-align: center;
    border: none;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    display: inline-block;
    font-family: 'Avenir 85';
    margin: 0 0 0 0;
    background: #1b1b1b;
}

.gen_settings_bottom {
    padding: 15px 0;
}

.gen_settings_row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 12px;
}

.gen_set_right {
    margin-left: auto;
}

    .gen_set_right select {
        width: 100%;
        border: 1px solid #DAD9D9;
        height: 40px;
        border-radius: 7px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: none;
        padding: 0 15px;
        background-repeat: no-repeat;
        background-position: 95% center;
        font-size: 14px;
        min-width: 215px;
    }

.gen_set_left {
    font-size: 14px;
    color: #606060;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e7e7e7;
    -webkit-transition: .4s;
    transition: .4s;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .slider {
    background-color: #9b9b9b;
}

input:focus + .slider {
    box-shadow: 0 0 1px #9b9b9b;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

    .slider.round:before {
        border-radius: 50%;
    }

.setting_holder .nav_same {
    min-height: 600px;
}

.team_setting_table .dataTables_length {
    display: none;
}

.team_setting_table .dataTables_filter {
    display: none;
}

.team_setting_table th {
    border: none;
    font-size: 14px;
    color: #9B9B9B;
    font-weight: normal;
}

.team_setting_table .sorting_asc:after {
    display: none !important;
}

.team_setting_table .sorting_asc:before {
    display: none !important;
}

.team_setting_table .sorting:after {
    display: none !important;
}

.team_setting_table .sorting:before {
    display: none !important;
}

.team_setting_table .sorting:after {
    display: none !important;
}

.team_setting_table .sorting:before {
    display: none !important;
}

.team_setting_table .add_pay_section{
    padding-top: unset;
}

.team_setting_table .add_pic_section_setting{
    min-height: 320px;
}

.team_setting_table .add_pic_section_setting p{
    position: absolute;
    margin-top: 15px;
}

.team_setting_table .add_details{
    margin-top: 15px;
}

.gen_settings_bottom a.btn_remove {
    float: right;
    margin: 0
}

.gen_settings_bottom td,
.gen_settings_bottom th {
    vertical-align: middle;
    border: none;
}

.gen_settings_bottom input[type=text],
.gen_settings_bottom input[type=password] {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DAD9D9;
    border-radius: 7px;
    padding: 0 10px;
    height: 40px;
    font-size: 14px;
    outline: none;
}

.gen_settings_bottom select {
    width: 100%;
    border: 1px solid #DAD9D9;
    height: 40px;
    border-radius: 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 14px;
    min-width: 215px;
}

.btn_new_class {
    padding: 0 15px;
}

    .btn_new_class a {
        width: 100%;
        height: 40px;
        border: 1px dashed #bdbdbd;
        border-radius: 8px;
        display: block;
        text-align: center;
        font-size: 14px;
        color: #2E2E2E;
        line-height: 40px;
        outline: none;
    }

.gen_settings_bottom .dataTables_info {
    display: none;
}

.gen_settings_bottom .dataTables_paginate {
    display: none;
}

.division input[type=text] {
    width: 90px;
}

.gen_settings_bottomwith_bor td,
.gen_settings_bottomwith_bor th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
}

.nosidepadding {
    padding-left: 0;
    padding-right: 0
}

.details_table_add td:first-child {
    width: 218px;
    white-space: nowrap;
}

.details_table_add td {
    vertical-align: middle !important;
}

a.btn_secactive {
    background: #2E2E2E;
    border-radius: 10px;
    display: block;
    width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    margin: 0 0 0 15px;
    opacity: 0.2;
    float: right;
}

    a.btn_secactive.active {
        opacity: 1.0
    }

.season_table {
    width: 100%
}

    .season_table td:last-child {
        width: 105px;
    }

.btchange_pass {
    display: block;
    text-align: right;
    font-size: 14px;
    color: #606060;
    margin-top: 10px;
}

.newteammodal .modal-content {
    border-radius: 12px;
    padding: 15px;
}

.newteammodal h2 {
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir 85';
    border-bottom: 1px solid #E7E7E7;
    padding: 0px 0 20px 0;
    margin: 0 0 15px 0
}

.adtezm td:first-child {
    width: auto
}

.newteammodal select {
    width: 100%;
    border: 1px solid #a1a1a1;
    height: 38px;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 14px;
    margin-bottom: 10px;
}

.newteammodal td {
    vertical-align: middle;
}

.movemodal .modal-content {
    border-radius: 12px;
    padding: 15px;
}

.movemodal h2 {
    font-size: 15px;
    color: #515151;
    font-family: 'Avenir 85';
    border-bottom: 1px solid #E7E7E7;
    padding: 0px 0 20px 0;
    margin: 0 0 15px 0
}

.adtezm td:first-child {
    width: auto;
    white-space: nowrap;
}

.movemodal select {
    width: 100%;
    border: 1px solid #a1a1a1;
    height: 38px;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0 15px;
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 14px;
    margin-bottom: 10px;
}

.movemodal td {
    vertical-align: middle;
}

.gap_arrow {
    margin-bottom: 10px;
}

.buttons_holder {
    display: flex;
    padding: 30px;
    flex-wrap: wrap;
}

    .buttons_holder a {
        margin: 0 30px;
    }

.number-circle {
    position: relative;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background: #c4c4c4;
    border: 2px solid #c4c4c4;
    color: #fff;
    text-align: center;
    margin-left: -9px;
}

.multiple-trainer_lg {
    display: flex;
    width: 60px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
    text-align: left;
}

.name:hover {
    text-decoration: none;
}

.label-circle-round {
    padding-top: 20%;
    font-size: 13px;
}

.trainer-trailing-image {
    width: 35px;
    height: 35px;
    z-index: 1;
}

a.plainlink:hover {
    color: #606060;
    font-size: 14px;
    height: 22px;
    width: 144px;
    letter-spacing: -0.1px;
    line-height: 22px;
}

a.plainlink {
    color: #606060;
    font-size: 14px;
    height: 22px;
    width: 144px;
    letter-spacing: -0.1px;
    line-height: 22px;
}

.season-btn button {
    width: 180px !important;
    min-width: 180px !important;
    height: 40px !important;
    text-align: center !important;
    font-weight: 400 !important;
    position: relative !important;
    font-size: 14px !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

button {
    outline: none;
}

    button:focus {
        outline: none;
        box-shadow: none;
    }

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: none !important;
}

.manager_pic {
    width: 50px;
    margin: 0 12px 0 0
}

.row-left-middle {
    align-items: center;
    display: flex;
    padding: 10px 5px 10px 0;
}

.row-middle {
    align-items: center;
    display: flex;
    padding: 10px 5px;
}

.row-right-middle {
    align-items: center;
    display: flex;
    padding: 10px 10px 10px 5px;
}

.row-left-middle img {
    margin-right: 10px;
    height: 50px !important;
}

.club img {
    margin-right: 10px;
    height: 50px !important;
}

.manager_pic img, .manager_pic div {
    width: 50px !important;
    height: 50px !important;
}

.MSeason table th:last-child {
    padding-right: 15px !important;
}

.MSeason table td:last-child {
    padding-right: 15px !important;
}
.hide {
    visibility:hidden;
}

.makeStyles-buttonInvitationDisable-22 {
    padding: 0 15px;
}

.dropdown-toggle::after {
    vertical-align: 2px !important;
    font-size: 24px;
    margin-left: 15px !important;
}

.managertable_holder tbodt tr:last-child td {
    border: none;
}

.MuiTableCell-root {
    line-height: initial !important;
    padding: 0px !important;
}

.MuiTableSortLabel-root {
    padding: 15px 0 !important;
}

.newpro {
    padding: 12px 0;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Avenir LT Std';
}

    .newpro a {
        font-family: 'Avenir LT Std';
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        text-decoration: none !important;
    }

        .newpro a:hover {
            font-family: 'Avenir LT Std';
            font-size: 14px;
            font-weight: 700;
            color: #fff;
        }

.dash_player_stat_table_holder .player_table_row {
    width: auto;
}

.dash_player_stat_table_holder .progress_bar {
    margin-right: 50px;
}


@media (min-width: 576px) {
    .modal-dialog {
        max-width: 650px !important;
        margin: 1.75rem auto;
    }

    .cell_5 {
        width: 5% !important;
    }

    .cell_10 {
        width: 10% !important;
    }

    .cell_15 {
        width: 15% !important;
    }

    .cell_20 {
        width: 20% !important;
    }

    .cell_40 {
        width: 40% !important;
    }
}

@media screen and (min-width: 992px) {

    .deletemodal {
        max-width: 460px;
    }

    .trainermodal {
        max-width: 650px;
    }

    .assignmodal {
        max-width: 780px;
    }

    .newteammodal {
        max-width: 370px;
    }

    .movemodal {
        max-width: 370px;
    }

    .cell_5 {
        width: 5% !important;
    }

    .cell_10 {
        width: 10% !important;
    }

    .cell_15 {
        width: 15% !important;
    }

    .cell_20 {
        width: 20% !important;
    }

    .cell_40 {
        width: 40% !important;
    }
}

@media only screen and (max-width: 1199px) {
    .closebtn {
        display: block
    }

    .dashboard_left {
    }

    #mySidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        box-sizing: border-box;
    }

    .mobile_menu {
        display: block;
    }

        .mobile_menu span {
            font-size: 30px;
            margin: 0 0 25px 0;
            display: inline-block;
        }

    .dashboard_left {
        position: relative;
    }

    .closebtn {
        position: absolute;
        right: 5px;
        top: 0px;
        color: #fff;
        font-size: 35px;
    }

    .cell_5 {
        width: 5% !important;
    }

    .cell_10 {
        width: 10% !important;
    }

    .cell_15 {
        width: 15% !important;
    }

    .cell_20 {
        width: 20% !important;
    }

    .cell_40 {
        width: 40% !important;
    }
}

@media only screen and (max-width: 992px) {

    .player_table_row {
        width: auto;
    }


    .login_container {
        flex-wrap: wrap;
        height: auto;
    }

    .login_left {
        width: 100%;
        padding: 50px;
    }

    .login_right {
        width: 100%;
        padding: 75px 30px;
    }

    .login_box {
        width: 100%;
        margin-left: 0px !important;
    }

    .login_footer {
        position: static;
    }

    .overview_body {
        padding: 15px;
    }

    .overview_top {
        padding: 0 15px;
        flex-wrap: wrap;
    }

    .season {
        width: 100%;
        padding-bottom: 10px
    }

    .dasboad_header_search {
        padding-top: 0
    }

        .dasboad_header_search .searcsubmit {
            top: 0
        }

    .overview_body_top_left {
        flex-wrap: wrap;
        width: 100%
    }

    .overview_body_top {
        flex-wrap: wrap;
    }

    .total_box {
        width: 100%;
        border: none;
        border-bottom: 1px solid #E7E7E7;
        padding: 10px 0;
    }

    .overview_filter_section {
        margin: 0 auto;
        padding: 12px 0 0
    }

    .first_box_top {
        flex-wrap: wrap;
    }

    .first_box_top_cn {
        width: 100%;
        border: none;
        border-bottom: 1px solid #E7E7E7;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .first_box_bottom {
        flex-wrap: wrap;
    }

    .overview_filter_section select {
        width: 170px;
    }

    .closebtn {
        display: block;
    }

    .dasboad_header_right {
        flex-wrap: wrap;
    }

    .profile_box_holder {
        width: 100%;
        border: none;
    }

    .details_top {
        flex-wrap: wrap;
    }

    .details_name {
        margin-top: 15px;
    }

    .accro_hd {
        flex-wrap: wrap;
    }

    .accro_name {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .datatable_bottom {
        overflow: auto;
    }

    table.table-bordered.dataTable td:last-child {
        border-right: 1px solid #dee2e6
    }

    table.table-bordered.dataTable th:last-child {
        border-right: 1px solid #dee2e6
    }

    table.table-bordered.dataTable td {
        white-space: nowrap;
    }

    .datatable_top {
        flex-wrap: wrap;
    }

    .datatable_top_left {
        width: 100%;
        margin-bottom: 10px;
    }

    .dasboad_header_right {
        margin-left: inherit;
        width: 100%
    }

    .dasboad_header_search {
        width: 100%;
        margin: 10px 0;
    }

    .setting_holder .nav_same {
        min-height: initial;
    }

    .datatable_top_right {
        margin-left: 0;
        flex-wrap: wrap;
    }

    .add_pay_section {
        flex-wrap: wrap;
    }

    .add_pic_section {
        margin: 0 auto
    }

    .add_details {
        padding: 0;
        overflow: auto;
    }

    .details_table_add {
        width: 400px;
    }

        .details_table_add td:first-child {
            white-space: nowrap;
            width: auto;
        }

    .search_submit {
        top: 0 !important
    }

    .cell_5 {
        width: 5% !important;
    }

    .cell_10 {
        width: 10% !important;
    }

    .cell_15 {
        width: 15% !important;
    }

    .cell_20 {
        width: 20% !important;
    }

    .cell_40 {
        width: 40% !important;
    }

    .overview_content_box .round_progress {
        text-align: center;
        margin: 10px;
    }

    .overview_panel_adjustment {
        height: unset;
        max-height: unset;
    }

    .total-team {
        max-height: unset;
    }

}

@media only screen and (max-width: 767px) {
    .makeStyles-table-11 {
        min-width: 800px !important
    }

    .MuiTableSortLabel-root {
        white-space: nowrap !important
    }

    .MuiTableCell-head {
        width: 40px !important
    }

    .toggle_sw {
        margin: 0;
        padding: 0;
        border: none;
    }

    a.btn_remove {
        width: 100%;
        margin: 10px 0
    }

    .season-btn {
        width: 100%;
        margin: 0 0 10px;
    }

    .dropdown-menu.show {
        display: block;
        width: 100% !important;
    }

    a.btn_adnew {
        width: 100%;
        padding: 0 15px;
    }

    .MuiTable-root {
        width: 600px !important;
    }

    .overview_filter_section {
        flex-wrap: wrap;
        width: 100%
    }

    .psr {
        width: 100%;
    }

    .d-inline-block {
        width: 100%;
    }

    .react-select-drop__control {
        width: 100%;
        margin-bottom: 10px;
    }

    .btn-group, .btn-group-vertical {
        width: 100%;
    }

    .psr .season-btn {
        margin-left: 0;
        margin-right: 0;
    }

    .dropdown-menu.show {
        width: 100%
    }

    .season-btn button {
        width: 100% !important
    }

    .overview_panel_adjustment {
        height: auto;
        max-height: inherit
    }

    .dashbord-min-height {
        min-height: inherit;
        max-height: inherit;
    }

    .player_table_row {
        overflow: auto;
    }

    .cell_5 {
        width: 5% !important;
    }

    .cell_10 {
        width: 10% !important;
    }

    .cell_15 {
        width: 15% !important;
    }

    .cell_20 {
        width: 20% !important;
    }

    .cell_40 {
        width: 40% !important;
    }
}

@media only screen and (max-width: 580px) {
    .player_table_row .progress_bar {
        min-width: 0px;
        width: 0px;
    }
}

.nav_same {
    min-height: 600px;
}

.gen_settings_bottom {
    padding: 15px 0;
    font-family: 'Avenir LT Std';
}

.season_set_row {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 0 10px 0;
}

.season_ag {
    font-size: 14px;
    color: #606060;
    padding: 0 15px 0 0;
    width: 60px;
    flex-shrink: 0;
}

.season_yr {
    font-size: 14px;
    color: #606060;
    background: #FFFFFF;
    border: 1px solid #DAD9D9;
    border-radius: 7px;
    padding: 0 15px;
    line-height: 40px;
    min-width: 210px;
    flex-shrink: 0;
}

.season_num {
    background: #F1F1F1;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #606060;
    margin: 0 12px;
}

.season_dlt {
    margin-left: auto;
}

    .season_dlt a {
        border: 1px solid #9B9B9B;
        border-radius: 10px;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 34px;
        text-align: center;
    }

        .season_dlt a img {
            width: 20px;
        }

.season_report {
    font-size: 14px;
    color: #606060;
}

.padbtm {
    padding-bottom: 30px;
}

.update_btn_holder {
    border-top: 1px solid #E7E7E7;
    padding: 12px 0;
    margin-top: 56px;
    overflow: hidden;
}

    .update_btn_holder a.btn_update {
        margin-top: 0;
    }

        .update_btn_holder a.btn_update:hover {
            color: #fff;
        }

.add_pic_section_setting {
    flex-shrink: unset !important;
}

.club-user-detail-hover {
    background-color: #000;
    opacity: 0.4;
    left: 40px;
    width: 150px;
    height: 150px;
    position: absolute;
    top: 100px;
}

    .club-user-detail-hover :hover {
        display: inline-block;
    }

.dt_gap {
    padding: 0 10px;
    text-align: center;
}

.spi_holder {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.number-spinner {
    width: 70px !important;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    height: 38px;
    line-height: 38px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
}

    .number-spinner .btn {
        padding: 0 3px;
        margin-top: -3px;
        outline: none;
        box-shadow: none;
    }

    .number-spinner input[type=text] {
        border: none;
        padding: 0 5px;
        height: 36px;
        line-height: 35px;
        margin-bottom: 0;
    }

.details_table_add td {
    vertical-align: middle;
    font-size: 14px;
}

.react-datepicker-wrapper {
    width: 100%
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.row-score {
    color: #9B9B9B;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.43px;
    line-height: 25px;
    margin-left: 10px;
    /*        width: 100px !important;*/
}

.trainer_lg_team {
    width: 40px;
    height: auto;
    flex-shrink: 0;
    margin: 0 10px 0 0;
}

.trainercode_name {
    height: 22px;
    width: 46px;
    color: #606060;
    font-family: Avenir;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 22px;
    white-space: nowrap
}

a.btn_remove_active {
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    line-height: 40px;
    font-size: 14px;
    background-color: #515151;
    display: block;
    font-family: 'Avenir 85';
    margin: 0 15px 0 0;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-weight: 900;
    letter-spacing: -0.34px;
}

    a.btn_remove_active:hover {
        color: #FFFFFF;
    }

.btn_remove_active img {
    width: 20px;
    margin: auto;
    vertical-align: middle;
}

a.btn_remove_active.text-center.season-btn img {
    width: 7%;
    position: absolute;
    right: 10px;
    top: 18px;
    transition: 0.5s;
}

.remove_details_pg a.btn_remove_active {
    margin-right: 0
}

a.btn_remove_active img {
    filter: invert(100) brightness(300);
}

.set-up-new-password {
    height: 24px !important;
    width: 307px !important;
    color: #FFFFFF !important;
    font-family: Avenir !important;
    font-size: 32px !important;
    /*font-weight: 900 !important;*/
    letter-spacing: -0.77px !important;
    line-height: 24px !important;
    margin-bottom: 35px !important;
}



button:hover,
a:hover,
button:focus,
a:focus,
button:active,
a:active {
    text-decoration: none !important;
}

.makeStyles-buttonInvitationEnable-99 {
    padding: 0 10px !important
}

.MuiButton-text {
    padding: 6px 8px !important;
}

.MuiTableCell-paddingCheckbox {
    width: 40px !important
}

.season-inline {
    width: 100%;
    justify-content: center;
}

.season-btn button {
    padding: 0 10px !important;
}

.managertable_holder tbody td:last-child {
    /*text-align: center !important*/
}

.managertable_holder thead th:nth-child(2) {
    /*width: 20%*/
}

.managertable_holder thead th:nth-child(3) {
    /*width: 20%*/
}

.season-btn:empty {
    display: none;
}

.season_settingd_table td:last-child a {
    border: 1px solid #bdbdbd;
    padding: 7px;
    border-radius: 5px;
    margin: 0 5px;
    text-align: center
}

    .season_settingd_table td:last-child a img {
        margin: 0px;
        width: 18px;
    }

::-webkit-input-placeholder {
    color: #b2b2b2;
}

:-ms-input-placeholder {
    color: #b2b2b2;
}

::placeholder {
    color: #b2b2b2;
}

.MuiTableSortLabel-icon {
    opacity: 100 !important;
}

.trainer_detail_team {
    width: 35px;
    margin-right: 15px
}

.extra {
    height: 32px;
    width: 64px;
    color: #ABABAB;
    font-family: 'Avenir LT Std';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    margin-top: 5px;
}

.circle-text-percentage {
    height: 25px;
    width: 86px;
    color: #2E2E2E;
    font-family: 'Avenir LT Std';
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -0.58px;
    line-height: 24px;
    text-align: center;
}

.player_stat_table_holder {
    padding-left: 20px !important;
    font-family: 'Avenir LT Std';
}

.details_table_left input[type=text] {
    height: 40px;
    margin-top: 5px;
}

.train_op {
    font-size: 14px;
    color: #606060;
}

.disabled-field-bg {
    background-color: #fff !important;
}

.filter-overview {
    font-family: 'Avenir LT Std' !important;
    font-size: 14px !important
}

.team-name-detail {
    font-family: 'Avenir LT Std' !important;
}

.form-control {
    -moz-box-shadow: 0 0 8px rgba(255,255,255,.6) !important;
    box-shadow: 0 0 8px rgba(255,255,255,.6) !important;
}

.dashboard-playerdevelopment-score {
    width: 100%;
    text-align: right;
    margin-right: 10px;
    font-size: 25px;
    font-weight: bold;
}

.filter-text-overview {
    text-align: center !important;
}


.player_name_dashboard {
    font-size: 14px;
    color: #606060;
    margin: 0 12px 0 0px;
    min-width: 130px;
    font-family: "Avenir LT Std" !important;
}

.traincode_dashboard {
    flex-shrink: 0;
    margin: 0 10px 0 0;
    font-size: 14px;
    color: #606060;
    padding: 0 0 0 10px;
    width: 90px;
}


.team_progress_pic {
    width: 50px;
    margin: 0 15px 0 0
}

    .team_progress_pic img {
        width: 50px !important;
        height: 50px !important;
        margin-left: 5px !important;
    }

.para_span {
    font-size: 14px;
    color: #606060;
    letter-spacing: -0.34px;
    line-height: 19px;
    font-family: 'Avenir LT Std';
}

.cell_5 {
    width: 5% !important;
}

.cell_10 {
    width: 10% !important;
}

.cell_15 {
    width: 15% !important;
}

.cell_20 {
    width: 20% !important;
}

.cell_40 {
    width: 40% !important;
}

.button-normal:focus {
    outline: none;
}

.button-normal {
    background-color: white;
    border-color: #9b9b9b;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    color: #9b9b9b;
    cursor: pointer;
    display: flex;
    font-family: 'Avenir 85';
    font-size: 14px;
    line-height: 24px;
    /*margin: 0 10px 0 10px;*/
    padding: 10px;
}

    .button-normal.add {
        background-color: #2a2a2a;
        border-color: #2a2a2a;
        color: white;
        margin-left: 10px;
    }

    .button-normal.on {
        background-color: #515151;
        border-color: #515151;
        color: white;
    }

    .button-normal.grey {
        background-color: #9b9b9b;
        border-color: #9b9b9b;
        color: white;
    }

    .button-normal img {
        height: 20px;
    }

.title-detail-arrow {
    margin-bottom: -5px !important;
}

.accro_details-trainers {
    font-size: 14px;
    color: #515151;
    font-family: "Avenir 85";
}

.accrod_date_detail_report {
    text-align: center !important;
}

.add_or_min {
    font-size: 1.5rem !important;
}

.validation_box {
    font-family: "Avenir LT Std";
    width: 600px !important;
}

    .validation_box h1{
        color: white;
        font-size: 30px;
    }

    .validation_box p{
        color: #a29f9f;
        font-size: 16px;
    }

.checkbox {
    height: 50px;
    margin-top: 10px;
    position: relative;
}

    .checkbox label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 25px;
        position: absolute;
        top: 50%;
        width: 25px;
        margin: 0;
        transform: translateY(-50%);
        cursor: default;
    }

        .checkbox label:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 6px;
            left: 5px;
            opacity: 0;
            position: absolute;
            top: 7px;
            transform: rotate(-45deg);
            width: 12px;
        }

    .checkbox input[type="checkbox"] {
        display: none;
        visibility: hidden;
    }

        .checkbox input[type="checkbox"]:checked + label {
            background-color: black;
            border-color: black;
        }

            .checkbox input[type="checkbox"]:checked + label:after {
                opacity: 1;
            }

.vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.left50 {
    margin-left: 50px;
}

.table-row-border-bottom {
    border-bottom: 1px solid #f1f1f1;
}

.accordion-box {
    font-family: 'Avenir LT Std';
    padding: 15px 0;
}

.box {
    font-family: 'Avenir LT Std';
}

    .box .header {
        align-items: center;
        background: white;
        box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        justify-content: space-between;
        padding-top: 10px;
    }

.header-content {
    border-bottom: solid 1px #f1f1f1;
    display: flex;
    margin: 0 15px;
    padding-bottom: 10px;
}

.body-content {
    align-content: center;
}

.container-left {
    background: white;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 15px;
    margin: 30px 0 30px 30px;
    font-family: 'Avenir LT Std';
}

.container-right {
    margin: 30px 30px 0 0;
    font-family: 'Avenir LT Std';
}

.toolbar {
    align-items: center;
    background: white;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    padding: 15px;
}

.std {
    font-family: 'Avenir LT Std';
}

.std-b-51 {
    color: #515151;
    font-family: 'Avenir LT Std';
    font-weight: 600;
}

.collapse.show {
    padding-bottom: 15px;
}

.img-user_detail-print {
    display: none;
}

@media print {
    body {
        overflow-x: visible;
    }
    
    #root {
        padding: 1cm;
    }

    .overview_body {
        margin-top: 0 !important;
    }

    .container-right {
        margin: 30px;
    }

    .dashboard_left {
        background: initial;
        width: 0 !important;
        height: 0;
    }

    #mySidenav .team_logo img {
        position: fixed;
        right: 82px;
        height: 150px;
        width: auto;
    }
    
    .team_logo {
        margin: 45px auto 0;
        padding: 0;
    }

    #mySidenav {
        background-color: initial;
    }

    .sidenav {
        display: none;
    }

    .dashboard_right {
        background-color: initial;
    }

    .shadow_container {
        position: fixed;
        padding-top: 15px;
    }

    .img-user_detail-print {
        display: initial;
        height: 150px;
        width: 150px;
        object-fit: cover;
        border-radius: 50%;
    }

    .add_pic_section .upload_btn {
        display: none;
    }

    .details_box .details_name {
        font-size: 28px;
    }

    .details_box .trains_detab .birthdate {        
        display : contents;
    }
    .details_box .trains_detab {
        margin-top: 0;
    }

    .accro_hd .accro_img {
        padding-top: 3px;
    }

    .overview_top,
    .backto,
    div.remove_details_pg,
    .add_pic_section .remove-image,
    .overall_section,
    .box .header,
    .toolbar,
    .col-lg-6,
    .accro_comment {
        display: none;
    }

    .col-md-5, .col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .footer {
        position: fixed;
        padding-bottom: 1cm;
        background-color: initial;
        color: black;
        font-size: 16px;
    }

    .collapse {
        display: block !important;
    }

    .report-skill-column-border-left, .report-skill-column-border-right {
        width: 200px;
    }
    .accrd_detils_repet {
        page-break-after: always;
        margin-top: 200px;
    }

    .accordion-box > .box:not(:first-child) .accrd_detils_repet,
    .accordion-box > .box:first-child .body-content > .accrd_detils_repet:not(:first-child) {
        margin-top: 290px;
    }

    .accro_details-trainers {
        font-size: 20px;
    }

    .completed-side-report.score {
        font-size: 38px;
    }

    .table-report-detail, .accro_feedback h3, .accro_feedback p {
        font-size: 16px;
    }

    .rat_number, .rat_name {
        font-size: 18px;
    }

}

/* Force printing background */
* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    color-adjust: exact !important;                 /* Firefox */
}

/* Disable print page margin */
@page {
    margin: 0;
}

.box .header.history_header,
.history_header .skill-expand-arrow {
    background-color: #f5f5f5;
    color: #999;
}

.history_header .accro_details.accro_details-trainers {
    color: #999;
}