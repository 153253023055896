@font-face {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-display: auto; /* or block, swap, fallback, optional */
    src: local('Avenir Medium'), /* will be preloaded */
    url('Avenir-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir 85';
    src: url('Avenir-Heavy.eot');
    src: url('Avenir-Heavy.eot?#iefix') format('embedded-opentype'), url('Avenir-Heavy.woff2') format('woff2'), url('Avenir-Heavy.woff') format('woff'), url('Avenir-Heavy.ttf') format('truetype'), url('Avenir-Heavy.svg#Avenir-Heavy') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('AvenirLTStd-Roman.eot');
    src: url('AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'), url('AvenirLTStd-Roman.woff2') format('woff2'), url('AvenirLTStd-Roman.woff') format('woff'), url('AvenirLTStd-Roman.ttf') format('truetype'), url('AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: auto; /* or block, swap, fallback, optional */
    src: local('Montserrat'), /* will be preloaded */
    url('Montserrat-Black.ttf') format('truetype'),
        url('Montserrat-BlackItalic.ttf') format('truetype'),
        url('Montserrat-Bold.ttf') format('truetype'),
        url('Montserrat-BoldItalic.ttf') format('truetype'),
        url('Montserrat-ExtraBold.ttf') format('truetype'),
        url('Montserrat-ExtraBoldItalic.ttf') format('truetype'),
        url('Montserrat-ExtraLight.ttf') format('truetype'),
        url('Montserrat-ExtraLightItalic.ttf') format('truetype'),
        url('Montserrat-Italic.ttf') format('truetype'),
        url('Montserrat-Light.ttf') format('truetype'),
        url('Montserrat-LightItalic.ttf') format('truetype'),
        url('Montserrat-Medium.ttf') format('truetype'),
        url('Montserrat-MediumItalic.ttf') format('truetype'),
        url('Montserrat-Regular.ttf') format('truetype'),
        url('Montserrat-SemiBold.ttf') format('truetype'),
        url('Montserrat-SemiBoldItalic.ttf') format('truetype'),
        url('Montserrat-Thin.ttf') format('truetype'),
        url('Montserrat-ThinItalic.ttf') format('truetype');
    unicode-range: U+000-5FF; /* Latin glyphs */
}