.p-text {
    font-size: 13px;
}

.center-modal {
    position: relative;
    margin: 0 auto;
    top: 5%;
}

/* Important part */
.modal-dialog {
    overflow-y: initial !important
}

.modal-body {
    height: 400px;
    overflow-y: auto;
}

.modal-footer {
    justify-content: flex-start !important;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    border-color: darkgrey;
}

    .checkbox-round:checked {
        background-color: gray;
    }

.accept-text {
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
}

    .accept-text a {
        color: black;
        text-decoration: underline;
    }

        .accept-text a:hover {
            color: black;           
        }


.form-termsandprivacy {
    width: 100%;
}

.loginform_privacy {
    margin: 20px 0;
    width: 100%;
    height: 44px;
    background: #c4c4c4;
    text-align: center;
    line-height: 44px;
    color: #000;
    font-family: 'Avenir 85';
    font-size: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.loginform_privacy input[type=button]:hover {
        background: #fff;
    }
