.login_background_image {
    background: url('../../images/login_banner.jpg') no-repeat;
    background-size: cover;
}

.Toastify__toast-container {
    width: 320px;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3498db;
}

.Toastify__toast--success {
    background: #101010;
}

.Toastify__toast--warning {
    background: #f1c40f;
}

.Toastify__toast--error {
    background: #e74c3c;
}
.validate-ok {
    color: #85B94F;
}