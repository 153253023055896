.modal-content {
    padding: 40px 40px 40px 40px !important;
}

.MuiTableRow-root {
    height: 45px !important;
}

.th-settings {
    font-family: "Avenir LT Std" !important;
    font-size: 14px !important;
    height: 22px;
    width: 100px;
    color: #606060;
    letter-spacing: -0.1px;
    line-height: 22px;
}

.edit-icon {
    padding-right: 10px;
}

.datatable_top_left_settings {
    height: 19px;
    color: #515151;
    font-family: "Avenir LT Std" !important;
    font-size: 15px;
    letter-spacing: -0.11px;
    line-height: 19px;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 900;
}

.upload-detail-settings {
    position: absolute;
    width: 200px;
    height: 200px;
    opacity: 0;
}

.th-settings-first {
    font-family: 'Avenir LT Std' !important;
    height: 19px;
    width: 106px;
    color: #606060;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.34px;
    line-height: 19px
}

a.btn_add_season {
    color: #606060 !important;
}

    a.btn_add_season:hover {
        color: #606060 !important;
    }

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.update_btn_holder:hover {
    color: #fff !important;
}


.input-group-settings {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;    
}

.tr-setting {
    line-height: 50px;
}

/*.mb-4 {
    margin-bottom: 0px !important;
}
*/

.add_pay_section .upload_btn{
    min-height: 165px;
}