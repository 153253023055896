.over_t {
    flex-shrink: 0;
    font-size: 14px;
    color: #606060;
    padding: 0 15px 0 0;
}

.over_pro {
    width: 100%;
}

.progress {
    height: 11px;
    background: #F1F1F1;
    border-radius: 50px;
}

.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}

.progress_bar {
    position: relative;
    padding: 0 30px 0 0;
    margin-left: auto;
    min-width: 120px;
}

.progress-bar {
    background: #2E2E2E;
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width .6s ease;
}

.rat_number {
    margin-left: auto;
    font-size: 14px;
    color: #2E2E2E;
}

    .rat_number span {
        border: 1px solid #2E2E2E;
        border-radius: 7px;
        padding: 5px 10px;
    }

a:hover {
    text-decoration: none;
}


.image-detail-circle {
    height: 50px;
    width: 50px;
}

.checklist-small-checklist-smal {
    height: 20px;
    width: 20px;
}

.completed-score {
    text-align: right;
    height: 19px;
    width: 36px;
    color: #515151;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -0.17px;
    line-height: 19px;
    margin: auto;
}

.accro_details-player {
    font-size: 14px;
    color: #515151;
    font-family: "Avenir 85";
    margin-bottom: 15px;
}

.skill-expand-arrow-player {
    margin-top: 20px;
    margin-right: 11px;
}

.overall_section {
    background: white;
    border-radius: 10px;
    box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.05);
    padding: 10px 5px 0 15px;
}

.remove_details_pg {
    display: flex;
}